import { getActiveClient } from 'app/selectors'
import { useIsMedium } from 'hooks/useIsMobile'
import React from 'react'
import { useSelector } from 'react-redux'
import { Badge, Col, Row } from 'reactstrap'
import { FiltersDrawer } from './filtersDrawer'
import actionRequiredIcon from 'assets/images/customer_dashboard_icons/Exlamation-icon-no-padding.png'
import readyToProcessIcon from 'assets/images/customer_dashboard_icons/Grey-checkmark-ready-to-process.png'

export interface QuickFiltersProps {
  onFilterChange: (filter: { [x: string]: boolean }) => void
  selectedFilters: object
}

export const QuickFilters = ({ selectedFilters, onFilterChange }: QuickFiltersProps) => {
  const activeClient = useSelector(getActiveClient) || {}
  const isMobile = useIsMedium()
  const handleFilterChange = (filter: { [x: string]: boolean }, mutuallyExclusiveWith: string[] = []) => {
    const filters = filter
    mutuallyExclusiveWith.forEach((name) => {
      filters[name] = false
    })
    onFilterChange(filters)
  }

  const quickFilters: { label: string; name: string; mutuallyExclusiveWith?: string[]; icon?: any }[] = [
    {
      label: 'Action required',
      name: 'action_required',
      icon: actionRequiredIcon,
    },
    {
      label: 'Ready for transactions',
      name: 'complete',
      icon: readyToProcessIcon,
    },
  ]

  if (activeClient.integration === 'quickbooks') {
    quickFilters.push({
      label: 'Connected to QuickBooks Online',
      name: 'quickbooks',
      mutuallyExclusiveWith: ['xero'],
    })
  }
  if (activeClient.integration === 'xero') {
    quickFilters.push({
      label: 'Connected to Xero',
      name: 'xero',
      mutuallyExclusiveWith: ['quickbooks'],
    })
  }

  return (
    <div>
      <Row className="align-items-center mb-2">
        {isMobile ? (
          <Col>
            <FiltersDrawer
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              quickFilters={quickFilters}
            />
          </Col>
        ) : (
          <>
            <Col>
              <Row className="justify-content-start">
                {quickFilters.map((filter, i) => (
                  <Col key={i} className="mb-1 p-1" xs="auto">
                    <Badge
                      pill
                      className={'btn' + (selectedFilters[filter.name] ? ' selected-filter' : ' unselected-filter')}
                      onClick={() =>
                        handleFilterChange(
                          { [filter.name]: !selectedFilters[filter.name] },
                          filter.mutuallyExclusiveWith,
                        )
                      }
                    >
                      <span className="btn btn-rotessa-tertiary pt-0 pb-0 no-wrap">
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                          {filter.icon && <img src={filter.icon} height={15} className="mr-1 pr-0" alt="" />}
                          {filter.label}
                        </div>
                      </span>
                    </Badge>
                  </Col>
                ))}
                <Col className="vertically-centered">
                  <FiltersDrawer selectedFilters={selectedFilters} onFilterChange={handleFilterChange} />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}
