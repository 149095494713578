import { QUICKBOOKS, XERO } from 'app/constants'
import quickbooksIcon from 'assets/images/quickbooks_logo_2024.png'

import xeroIcon from 'assets/images/xero_icon.png'

export const switchIntegration = (name, quickbooks, xero) => {
  return name === QUICKBOOKS ? quickbooks : name === XERO ? xero : ''
}

export const integrationName = (name) => switchIntegration(name, 'QuickBooks Online', 'Xero')
export const integrationIcon = (name) => switchIntegration(name, quickbooksIcon, xeroIcon)
